import React from 'react';
import { Link } from 'react-scroll';

import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarMenu,
  SidebarLink,
  SidebarRoute,
  SideBtnWrap,
} from "./SidebarElements";

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarMenu>
        <SidebarLink><Link to="menu" smooth={true} duration={500} offset={-70} onClick={toggle} style={{ cursor: 'pointer'}} >
          Full Menu
        </Link></SidebarLink> 
        <SidebarLink to="https://www.doordash.com/store/carmens-la-hacienda-novato-1170121/">Delivery</SidebarLink>
        <SidebarLink to="https://www.carmenslahacienda.com/">Locations</SidebarLink>
        
      </SidebarMenu>
      <SideBtnWrap>
        <SidebarRoute to="https://www.doordash.com/store/carmens-la-hacienda-novato-1170121/">Order Now</SidebarRoute>
      </SideBtnWrap>
    </SidebarContainer>
  );
};

export default Sidebar;
