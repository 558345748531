import React from "react";
import './AccessibilityModal.css'

function AccessibilityModal({ isOpen, onClose }) {
    if (!isOpen) return null;


    return (
      <div className="accessibility-modal-overlay">
        <div className="accessibility-modal-content">
          <button className="ally-close-button" onClick={onClose}>
            X
          </button>
          <div class="ally-statement">
            <p><strong>Accessibility Statement</strong></p>

            <p>
              Carmen's is committed to facilitating the accessibility and
              usability of its Web site,&nbsp;
              <a href="https://novato.carmenslahacienda.com/">novato.carmenslahacienda.com</a>, for
              all people with disabilities. Working with a retained
              accessibility consultant, Carmens' will be implementing over
              time the relevant portions of the World Wide Web Consortium’s Web
              Content Accessibility Guidelines 2.0 Level AA (WCAG 2.0 AA) as its
              web accessibility standard, which will also bring Carmens'
              into conformance with the Section 508 Web Accessibility Standards
              developed by the United States Access Board (Section 508).
            </p>
            <p>
              Carmens' has been working with&nbsp;
              <a
                href="http://ncam.wgbh.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                The National Center for Accessible Media at WGBH (NCAM)
              </a>
              &nbsp;to conduct website accessibility audit(s), provide related
              training and consulting, and to provide Carmen's with
              specific recommendations to facilitate website accessibility and
              conformance with WCAG 2.0 AA standards.
            </p>
            <p>
              <strong>
                Contact&nbsp;Carmen's With Your Accessibility Questions
              </strong>
            </p>
            <p>
              Please be aware that our efforts are ongoing. If, at any time, you
              have specific questions or concerns about the accessibility of any
              particular Web page on&nbsp;
              <a href="https://novato.carmenslahacienda.com.com/">novato.carmenslahacienda.com</a>
              &nbsp;please contact us at&nbsp;
              <a href="mailto:info@carmenslahacienda.com">
              info@carmenslahacienda.com
              </a>
              . If you do encounter an accessibility issue, please be sure to
              specify the Web page in your email, and we will make all
              reasonable efforts to make that page accessible for you.
            </p>
          </div>
        </div>
      </div>

      
    );
}

export default AccessibilityModal;